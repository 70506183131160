import React, { useState } from 'react';
import { supabase } from './supabaseClient'; // Ensure this path is correct for your project
import { loadStripe } from '@stripe/stripe-js';

const stripePublicKey = 'pk_live_51MC8UYFtbHsObHDE4pCwfuKuJq7B5gRRgNyRMSWczuZTyyOX3rcvkw1LMjeVGAopDlcMpc4G1AuIKo7NmksEyMER00ckZNNSuv'; // Replace with your actual Stripe public key
const stripePromise = loadStripe(stripePublicKey);

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages

  const redirectToStripeCheckout = async () => {
    const stripe = await stripePromise;
    const priceId = 'price_1PQeqLFtbHsObHDEgZWxgAIO'; 

    const discountCode = 'i9gyQZ6U'; // Hardcoded discount code

    try {
      const response = await fetch('https://api.trendingdata.ai/create-trends-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId,
          email,
          discountCode, // Pass the discount code to the server
        }),
      });
      const session = await response.json();
      if (session.url) {
        window.location.href = session.url;
      } else {
        setErrorMessage('There was an issue with your request.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Sorry, there was an error. Please try again.');
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signUp({ email, password });
    if (error) {
      setErrorMessage(error.message); // Set error message
    } else {
      // Redirect to Stripe checkout
      await redirectToStripeCheckout();
    }
  };
  
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-5 w-auto"
          src="/logo.png"
          alt="Company Logo" // Added alt attribute
        />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign up for an account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {errorMessage && (
            <div className="mb-4 text-red-500 text-sm">
              {errorMessage}
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSignUp}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Sign up
              </button>
            </div>
          </form>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already have an account?{' '}
          <a href="/signin" className="font-semibold leading-6 text-blue-500 hover:text-blue-400">
            Sign in
          </a>
        </p>
      </div>
    </div>
  );
}
