// src/utils/Sorting.js

/**
 * Parses the growth value from a string to a number.
 * Assumes the growth value is a percentage string (e.g., "7100.0%").
 * @param {string} growth - The growth value as a string.
 * @returns {number} - The parsed growth value as a number.
 */
const parseGrowth = (growth) => {
  // Remove the percentage sign and convert to a number
  return parseFloat(growth.replace('%', ''));
};

/**
 * Sorts the data by most growth.
 * @param {Array} data - The dataset to be sorted.
 * @returns {Array} - The sorted dataset.
 */
export const sortByMostGrowth = (data) => {
  return [...data].sort((a, b) => parseGrowth(b.growth) - parseGrowth(a.growth));
};

/**
 * Sorts the data by least growth.
 * @param {Array} data - The dataset to be sorted.
 * @returns {Array} - The sorted dataset.
 */
export const sortByLeastGrowth = (data) => {
  return [...data].sort((a, b) => parseGrowth(a.growth) - parseGrowth(b.growth));
};

/**
 * Returns the original order of the data.
 * @param {Array} originalData - The original dataset.
 * @returns {Array} - The dataset in its original order.
 */
export const sortByOriginalOrder = (originalData) => {
  return originalData;
};

/**
 * Sorts the data by most volume (based on the most recent month's search volume).
 * @param {Array} data - The dataset to be sorted.
 * @returns {Array} - The sorted dataset.
 */
export const sortByMostVolume = (data) => {
  return [...data].sort((a, b) => {
    const aRecentVolume = parseFloat(a.searchVol[a.searchVol.length - 1][1]) || 0;
    const bRecentVolume = parseFloat(b.searchVol[b.searchVol.length - 1][1]) || 0;
    return bRecentVolume - aRecentVolume;
  });
};

/**
 * Sorts the data by least volume (based on the most recent month's search volume).
 * @param {Array} data - The dataset to be sorted.
 * @returns {Array} - The sorted dataset.
 */
export const sortByLeastVolume = (data) => {
  return [...data].sort((a, b) => {
    const aRecentVolume = parseFloat(a.searchVol[a.searchVol.length - 1][1]) || 0;
    const bRecentVolume = parseFloat(b.searchVol[b.searchVol.length - 1][1]) || 0;
    return aRecentVolume - bRecentVolume;
  });
};

/**
 * Calculates the growth percentage based on the provided data field.
 * @param {Array} data - The data field from MongoDB.
 * @returns {string} - The calculated growth percentage as a string.
 */
const calculateGrowthFromData = (data) => {
  if (data.length < 2) return '0%';
  let initial = data[0][1];
  const final = data[data.length - 1][1];
  if (initial === 0) initial = 1; // Replace 0 with 1 to avoid infinite growth values
  const growth = ((final - initial) / initial) * 100;
  return `${Math.round(growth)}%`;
};

/**
 * Filters the data by date range and calculates the growth percentage for the respective timeframe.
 * @param {Array} data - The dataset to be filtered.
 * @param {string} range - The selected date range ('1 Year', '2 Years', '5 Years').
 * @returns {Array} - The filtered dataset with updated growth percentages.
 */

export const filterByDateRange = (data, range) => {
  let months;
  switch (range) {
    case '3 Months':
      months = 3;
      break;
    case '6 Months':
      months = 6;
      break;
    case '1 Year':
      months = 12;
      break;
    case '2 Years':
      months = 24;
      break;
    case '5 Years':
    default:
      months = 60;
      break;
  }
  return data.map(dataset => {
    const filteredData = dataset.data.slice(-months);
    const filteredSearchVol = dataset.searchVol.slice(-months);
    const updatedGrowth = calculateGrowthFromData(filteredData);
    return {
      ...dataset,
      data: filteredData,
      searchVol: filteredSearchVol,
      growth: updatedGrowth
    };
  });
};
