// src/components/Auth/Profile.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Ensure this path is correct for your project
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleUnsubscribe = async () => {
    setIsConfirmOpen(true);
  };

  const confirmUnsubscribe = async () => {
    setIsConfirmOpen(false);
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error getting user:', error);
        setPopupMessage('An error occurred while unsubscribing. Contact Us');
        setIsOpen(true);
        return;
      }

      if (!user) {
        setPopupMessage('User not authenticated. Contact Us');
        setIsOpen(true);
        return;
      }

      const response = await fetch('https://api.trendingdata.ai/unsubscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({ email: user.email })
      });

      const data = await response.json();
      if (response.ok) {
        setPopupMessage('Successfully unsubscribed');
      } else {
        setPopupMessage('Error cancelling subscription. Please Contact Us.');
      }
    } catch (error) {
      console.error('Error unsubscribing:', error);
      setPopupMessage('Error cancelling subscription. Please Contact Us.');
    }
    setIsOpen(true);
  };

  const handleContactUs = () => {
    window.location.href = 'mailto:support@trendingdata.ai';
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <Link to="/">
            <img className="h-5 w-auto" src="/logo.png" alt="Logo" />
          </Link>
        </div>
      </header>
      <main className="flex flex-col items-center justify-center flex-grow pt-8">
        <div className="bg-white shadow-md rounded-lg p-8 max-w-lg w-full">
          <h1 className="text-2xl font-semibold mb-6 text-center">Profile</h1>
          <div className="flex space-x-4">
            <button
              onClick={handleUnsubscribe}
              className="flex-1 bg-red-500 text-white px-4 py-2 rounded-md shadow hover:bg-red-600 transition duration-300"
            >
              Unsubscribe
            </button>
            <button
              onClick={handleContactUs}
              className="flex-1 bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition duration-300"
            >
              Contact Us
            </button>
          </div>
        </div>
      </main>

      {/* Confirmation Dialog */}
      <Transition show={isConfirmOpen}>
        <Dialog className="relative z-10" onClose={() => setIsConfirmOpen(false)}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="p-6">
                    <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Are you sure you want to unsubscribe?
                    </DialogTitle>
                    <div className="mt-4 flex justify-end space-x-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-400"
                        onClick={() => setIsConfirmOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-red-500 px-4 py-2 text-sm text-white shadow-sm hover:bg-red-600"
                        onClick={confirmUnsubscribe}
                      >
                        Unsubscribe
                      </button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Popup Dialog */}
      <Transition show={isOpen}>
        <Dialog className="relative z-10" onClose={() => setIsOpen(false)}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="p-6">
                    <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {popupMessage}
                    </DialogTitle>
                    {popupMessage.includes('Contact Us') && (
                      <div className="mt-4 flex justify-end space-x-4">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md bg-blue-500 px-4 py-2 text-sm text-white shadow-sm hover:bg-blue-600"
                          onClick={handleContactUs}
                        >
                          Contact Us
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-400"
                          onClick={() => setIsOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    )}
                    {!popupMessage.includes('Contact Us') && (
                      <div className="mt-4 flex justify-end">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-400"
                          onClick={() => setIsOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Profile;
