import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Sidebar from './utils/Sidebar'; // Import the Sidebar component

function Discover() {
  const [keyword, setKeyword] = useState('');
  const [region, setRegion] = useState('US');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState('avgMonthlySearches');
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const resultsPerPage = 50;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://api2.trendingdata.ai/trend-discovery', { keyword, geo: region });
      setResults(response.data.keywords);
      setCurrentPage(1); // Reset to first page on new search
    } catch (error) {
      console.error('Error fetching trend discovery data:', error);
      setError('An error occurred while fetching data. Please try again.');
    }
    setLoading(false);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('desc');
    }
  };

  const sortedResults = results ? [...results].sort((a, b) => {
    if (sortColumn === 'avgMonthlySearches') {
      return sortDirection === 'asc' ? a.avgMonthlySearches - b.avgMonthlySearches : b.avgMonthlySearches - a.avgMonthlySearches;
    } else if (sortColumn === 'growthPercentage') {
      return sortDirection === 'asc' ? a.growthPercentage - b.growthPercentage : b.growthPercentage - a.growthPercentage;
    }
    return 0;
  }) : null;

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = sortedResults ? sortedResults.slice(indexOfFirstResult, indexOfLastResult) : [];

  const totalPages = results ? Math.ceil(results.length / resultsPerPage) : 0;

  const getGrowthColor = (growth) => {
    if (growth > 0) return 'text-green-600';
    if (growth < 0) return 'text-red-600';
    return 'text-black';
  };

  const formatNumber = (num) => {
    return num.toLocaleString('en-US', { maximumFractionDigits: 0 });
  };

  const handleKeywordClick = (keyword) => {
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(keyword)}`;
    window.open(searchUrl, '_blank');
  };

  const search = (platform, keyword) => {
    let url;
    switch (platform) {
      case 'Google':
        url = `https://www.google.com/search?q=${encodeURIComponent(keyword)}`;
        break;
      case 'TikTok':
        url = `https://www.tiktok.com/search?q=${encodeURIComponent(keyword)}`;
        break;
      case 'Amazon':
        url = `https://www.amazon.com/s?k=${encodeURIComponent(keyword)}`;
        break;
      case 'Google Trends':
        url = `https://trends.google.com/trends/explore?date=today%205-y&geo=US&q=${encodeURIComponent(keyword)}&hl=en`;
        break;
      default:
        url = '';
    }
    if (url) window.open(url, '_blank');
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar /> {/* Include the Sidebar component */}
      <div className="flex-grow ml-52 py-8 px-2 sm:px-4 lg:px-6"> {/* Add left margin to main content */}
        <div className="max-w-4xl mx-auto">
          <h1 className="text-lg font-semibold text-left text-black mb-6">Discover Trends</h1>
          
          <form onSubmit={handleSubmit} className="mb-6">
            <div className="flex flex-col sm:flex-row gap-2">
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="What trends do you want to find? (ex. skincare products)"
                className="flex-grow px-3 py-1.5 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                required
              />
              <select
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                className="px-3 py-1.5 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              >
                <option value="US">United States</option>
                <option value="worldwide">Worldwide</option>
                <option value="UK">United Kingdom</option>
                <option value="CA">Canada</option>
                <option value="AU">Australia</option>
                <option value="IN">India</option>
                <option value="NZ">New Zealand</option>
                <option value="ZA">South Africa</option>
              </select>
              <button
                type="submit"
                className="px-4 py-1.5 bg-gradient-to-r from-sky-500 to-blue-600 hover:bg-blue-700 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-sm"
                disabled={loading}
              >
                {loading ? 'Searching...' : 'Search'}
              </button>
            </div>
          </form>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded relative mb-4 text-sm" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}

          {results && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 text-sm">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-2 text-left font-medium text-black tracking-wider">Keyword</th>
                    <th 
                      className="px-4 py-2 text-left font-medium text-black tracking-wider cursor-pointer"
                      onClick={() => handleSort('avgMonthlySearches')}
                    >
                      Avg Monthly Searches
                      {sortColumn === 'avgMonthlySearches' && (
                        <span className="ml-1">{sortDirection === 'asc' ? '▲' : '▼'}</span>
                      )}
                    </th>
                    <th 
                      className="px-4 py-2 text-left font-medium text-black tracking-wider cursor-pointer"
                      onClick={() => handleSort('growthPercentage')}
                    >
                      Growth
                      {sortColumn === 'growthPercentage' && (
                        <span className="ml-1">{sortDirection === 'asc' ? '▲' : '▼'}</span>
                      )}
                    </th>
                    <th className="px-4 py-2 text-left font-medium text-black tracking-wider">Links</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentResults.map((item, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 whitespace-nowrap text-blue-600 cursor-pointer hover:underline"
                          onClick={() => handleKeywordClick(item.keyword)}>
                        {item.keyword}
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-black">{formatNumber(item.avgMonthlySearches)}</td>
                      <td className={`px-4 py-2 whitespace-nowrap ${getGrowthColor(item.growthPercentage)}`}>
                        {formatNumber(Math.round(item.growthPercentage))}%
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-black relative">
                        <button
                          className="flex items-center text-blue-500 hover:text-blue-700 focus:outline-none"
                          onClick={() => setShowDropdown(showDropdown === index ? null : index)}
                        >
                          <ChevronDownIcon className="h-4 w-4" />
                        </button>
                        {showDropdown === index && (
                          <div ref={dropdownRef} className="absolute right-0 mt-2 w-28 bg-white border border-gray-200 rounded shadow-lg z-10 text-xs">
                            <ul>
                              <li className="px-2 py-1 hover:bg-gray-100 cursor-pointer" onClick={() => search('Google', item.keyword)}>Google</li>
                              <li className="px-2 py-1 hover:bg-gray-100 cursor-pointer" onClick={() => search('TikTok', item.keyword)}>TikTok</li>
                              <li className="px-2 py-1 hover:bg-gray-100 cursor-pointer" onClick={() => search('Amazon', item.keyword)}>Amazon</li>
                              <li className="px-2 py-1 hover:bg-gray-100 cursor-pointer" onClick={() => search('Google Trends', item.keyword)}>Google Trends</li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {results && totalPages > 1 && (
            <div className="flex justify-center items-center mb-8 mt-2">
              <div className="join">
                {currentPage > 1 && (
                  <button
                    className="join-item btn btn-xs"
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    «
                  </button>
                )}
                <span className="join-item btn btn-xs">
                  Page {currentPage} of {totalPages}
                </span>
                {currentPage < totalPages && (
                  <button
                    className="join-item btn btn-xs"
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    »
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Discover;
