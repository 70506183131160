// chartConfig.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return Math.round(num / 1000) + 'k'; // Round to whole number for values in thousands
  }
  return Math.round(num); // Round to whole number for values under 1000
};

// Common chart options extracted from the Trends component
export const getCommonChartOptions = (timeUnit = 'year', maxTicksLimit = 12, maxYValue) => {
  const numberOfSteps = 5; // Number of horizontal lines
  const stepSize = Math.ceil(maxYValue / numberOfSteps); // Calculate step size

  return {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeUnit,
          tooltipFormat: timeUnit === 'year' ? 'yyyy' : 'MMM yyyy',
          stepSize: timeUnit === 'month' ? 5 : 1, // Show every 3rd month if time unit is 'month'
        },
        grid: {
          display: false,
        },
        title: {
          display: false,
        },
        ticks: {
          maxTicksLimit: maxTicksLimit,
        },
      },
      y: {
        grid: {
          borderDash: [5, 5],
        },
        title: {
          display: false,
        },
        beginAtZero: true,
        max: stepSize * numberOfSteps, // Ensure the max value is aligned with the step size
        ticks: {
          stepSize: stepSize, // Set the step size for evenly spaced ticks
          maxTicksLimit: numberOfSteps + 1, // One more than the number of steps to include zero
          callback: function(value) {
            return formatNumber(value); // Format y-axis labels using formatNumber function
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        mode: 'index',
        intersect: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4, // Adjust for desired curvature
        borderWidth: 3,
        borderColor: '#0017BB', // Line color
      },
      point: {
        radius: 0, // Hide points
      },
    },
    animation: false, // Add this line to disable animations
  };
};
