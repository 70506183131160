import React, { useState, useEffect } from 'react';

const Typewriter = () => {
  const words = ["Entrepreneurs", "Builders", "Investors"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [charIndex, setCharIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const type = () => {
      const currentWord = words[currentWordIndex];
      if (isDeleting) {
        setCurrentText(currentWord.substring(0, charIndex - 1));
        setCharIndex(charIndex - 1);
        if (charIndex === 0) {
          setIsDeleting(false);
          setCurrentWordIndex((currentWordIndex + 1) % words.length);
        }
      } else {
        setCurrentText(currentWord.substring(0, charIndex + 1));
        setCharIndex(charIndex + 1);
        if (charIndex === currentWord.length) {
          setIsPaused(true);
          setTimeout(() => {
            setIsPaused(false);
            setIsDeleting(true);
          }, 1000); // Pause for 1.5 seconds
        }
      }
    };

    if (!isPaused) {
      const timeoutId = setTimeout(type, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [charIndex, isDeleting, currentWordIndex, words, isPaused]);

  return (
    <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-700">
      {currentText}
    </span>
  );
};

export default Typewriter;
