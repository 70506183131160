import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    question: "What am I buying?",
    answer:
      "Trending Data is a database of trending products, businesses, and industries. Each chart has insights about the trend and proprietary metrics like growth rate and search volume. The trend discovery hub is a tool that fetches trends for any niche, market, or category.",
  },
  {
    question: "How do you get your data?",
    answer:
      "We spend a ton of time (and resources) casting a wide net across the internet and fish up anything with buzz. We then rigorously sift through all the data and add everything relevent to the database.",
  },
  {
    question: "Is it really lifetime access? Or are there hidden fees?",
    answer:
      "Once you sign up, you're in for life. No additional costs. You get instant access the moment you checkout.",
  },
  {
    question: "How recent is the data?",
    answer:
      "Very recent! We're constantly adding new trends and updating the data of exisitng trends!",
  },
  {
    question: "Is there a free trial?",
    answer:
      'No free trial for the database, but we release free trend reports on our <a href="/newsletter" class="text-blue-500">newsletter</a> multiple times a month.',
  },
  {
    question: "Is the database only for current business owners?",
    answer:
      'Absolutely not! This database is also for anyone who wants to start a business or just wants to stay on top of shifting consumer preferences.',
  },
];

export default function FAQ() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 sm:px-32 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-6xl divide-y divide-gray-900/10">
            <h2 className="text-5xl font-bold leading-10 tracking-tight text-gray-900 text-center">But... I have questions</h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <DisclosureButton className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">{faq.question}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </DisclosureButton>
                      </dt>
                      <DisclosurePanel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    );
}
