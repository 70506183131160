import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import dbssImage from './imgs/dbss.png'; // Import the DBSS image
import discoverImage from './imgs/discover.png'; // Import the Discover image
import discoverTool from './imgs/discovertool.png'; // Import the Discover image
import { PresentationChartLineIcon, BoltIcon, AdjustmentsHorizontalIcon, RocketLaunchIcon, CheckBadgeIcon, GlobeAsiaAustraliaIcon, NumberedListIcon } from '@heroicons/react/20/solid';
import FAQ from './utils/FAQ'; // Import the FAQ component
import Header from './utils/Header'; // Import the new header component
import TrendExamples from './utils/TrendExamples'; // Import the TrendExamples component

const features = [
  {
    name: 'Product Trends.',
    description:
      "By analyzing millions of data points, we ensure products have demand.",
    icon: PresentationChartLineIcon,
  },
  {
    name: 'Stay in the Loop.',
    description: 'Ditch hours of research and understand what trends are up next.',
    icon: BoltIcon,
  },
  {
    name: 'Unique Insights.',
    description: 'Get actual search volume values that are not available on other keyword sites.',
    icon: AdjustmentsHorizontalIcon,
  },
];

const features2 = [
  {
    name: 'Trends in Your Niche.',
    description:
      "Want more data? Type any niche and get a list of trends in that specific market.",
    icon: NumberedListIcon,
  },
  {
    name: 'Millions of Data Points.',
    description: 'Unlock millions of trends across 8 regions at the click of a button.',
    icon: GlobeAsiaAustraliaIcon,
  },
  {
    name: 'Backed By Data.',
    description: 'Get search volume and growth values to quickly spot demand.',
    icon: RocketLaunchIcon,
  },
];

const LandingPage = () => {
  useEffect(() => {
    // Add any side effects or tracking code here
  }, []);

  return (
    <div className="relative bg-white">
      {/* Header Section */}
      <Header />

      <div className="max-w-5xl mx-auto lg:px-0">
        {/* Hero Section */}
        <div className="hero min-h-[5vh] bg-white pt-3 sm:pt-10 px-4 sm:px-10 flex flex-col items-center text-center bg-hero-pattern">
          {/* Hero Heading */}
          <h1 className="text-6xl lg:text-7xl font-bold leading-none sm:leading-none sm:pb-10 pb-2">
            <span className="text-black ">Tomorrow's Big Thing,</span>
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-blue-700"> Today</span>
          </h1>
          {/* Hero Description */}
          <p className="pb-10 text-gray-600 text-xl">find the products & businesses people <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-700">are searching for</span></p>
          {/* Get Trends Button */}
          <ScrollLink to="no-subscription-title" smooth={true} duration={500} className="btn text-white bg-gradient-to-r from-sky-500 to-blue-600 hover:bg-blue-700 focus:outline-none w-48 py-2 cursor-pointer mb-10">Get Trends</ScrollLink>
          {/* Image under Get Started Button */}
          <div className="relative overflow-hidden w-full max-w-full h-auto">
            <img src={dbssImage} alt="DBSS" className="pb-10 max-w-full h-auto rounded-xl shadow-2xl ring-1 ring-gray-400/10" />
            <div className="absolute inset-x-0 bottom-0 h-60 bg-gradient-to-t from-white to-transparent" />
          </div>
        </div>
      </div>

      {/* Tutorial Title Section */}
      <div id="product-section" className="hero bg-white pt-10 sm:pt-12">
        <div className="max-w-7xl mx-auto px-4 lg:px-0">
          {/* Tutorial Title */}
          <h2 className="text-4xl sm:text-5xl font-bold text-black sm:mb-4">
            You'll know trends <span className="bg-clip-text text-transparent bg-gradient-to-r from-sky-400 to-blue-700">before</span> the crowd
          </h2>
        </div>
      </div>

      {/* Tutorial Section */}
      <div className="flex justify-center items-center bg-white py-10 sm:py-16">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:flex-row flex-col">
            <div className="order-1 lg:order-1 lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Trends <span className="bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-blue-700">Database</span>
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-blue-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              src={discoverImage}
              alt="Discover"
              className="order-2 lg:order-2 w-full max-w-full h-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
          </div>
        </div>
      </div>

      {/* Features2 Section */}
      <div className="flex justify-center items-center bg-white py-10 sm:py-16">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:flex-row flex-col">
            <img
              src={discoverTool}
              alt="Discover"
              className="order-2 lg:order-1 w-full max-w-full h-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
            <div className="order-1 lg:order-2 lg:pl-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-blue-700">Discovery</span> Hub
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features2.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-blue-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TrendExamples Component Section */}
      <div className="bg-gray-50">
        <TrendExamples />
      </div>

      {/* Pricing Title Section */}
      <section id="no-subscription-title" className="bg-white py-8">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-1">
            <h2 className="text-4xl sm:text-5xl font-bold text-black mb-7">
              Hate subscriptions? <span className="bg-clip-text text-transparent bg-gradient-to-r from-sky-400 to-blue-700 ">So do we.</span>
            </h2>
            <p className="text-gray-700 text-lg sm:text-xl mt-2">Pay once and gets trends for life.</p>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing-section" className="bg-white pb-12">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="ring-2 ring-blue-500 rounded-3xl p-8 xl:p-10 shadow-2xl bg-white">
            <div className="flex items-center justify-between gap-x-4 flex-wrap">
              {/* Pricing Plan Title */}
              <h2 className="text-black text-2xl sm:text-3xl font-bold leading-8">Trending Data Unlimited</h2>
              {/* Special Offer Tag */}
              <p className="rounded-full bg-blue-500/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-500">33% Off</p>
            </div>
            {/* Pricing Amount */}
            <p className="mt-6 flex items-baseline gap-x-1">
              <span className="text-5xl font-bold tracking-tight text-gray-900">$99</span>
              <span className="ml-2 line-through text-gray-500 text-2xl">$149</span>
            </p>
            {/* Pricing Features */}
            <ul className="mt-6 space-y-3 text-sm leading-6 text-black xl:mt-6">
              <li className="flex gap-x-3 text-base font-bold">
                <CheckBadgeIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />
                <span>Database of 1,000+ Exploding Trends</span>
              </li>
              <li className="flex gap-x-3 text-base font-bold">
                <CheckBadgeIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />
                <span>Trend Discovery Hub</span>
              </li>
              <li className="flex gap-x-3 text-base">
                <CheckBadgeIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />
                <span>Trend Reports</span>
              </li>
              <li className="flex gap-x-3 text-base">
                <CheckBadgeIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />
                <span>Access For Life</span>
              </li>
            </ul>

            {/* Start Free Trial Button */}
            <Link to="/signup" className="bg-blue-500 text-white shadow-sm hover:bg-blue-400 mt-6 block rounded-md py-2 px-3 text-center text-base font-medium leading-6 transition duration-300 ease-in-out">
              Get Lifetime Access (33% Off)
            </Link>
            {/* Pricing Disclaimer */}
            <div className="text-center mt-6 text-gray-600">
              <span>Price increase coming soon (seriously)</span>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <FAQ />

      {/* Footer */}
      <footer className="bg-gray-100 text-black text-center py-4 overflow-hidden">
        &copy; 2023 Your Company. All rights reserved.
        <div>
          {/* Privacy Policy Link */}
          <Link to="/privacy" className="text-black hover:underline">Privacy Policy</Link>
          {/* Contact Us Link */}
          <a href="mailto:support@trendingdata.ai" className="text-black hover:underline ml-4">Contact Us</a>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
