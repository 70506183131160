import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Trends from './components/Trends';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import Landing from './components/Landing';
import Newsletter from './components/Newsletter';
import PasswordReset from './components/Auth/PasswordReset';
import Profile from './components/Auth/Profile';
import Discover from './components/Discover';  // Import the new Discover component
import { AuthProvider } from './components/Auth/AuthContext';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { supabase } from './components/Auth/supabaseClient';
import './index.css';

// Expose supabase globally for testing purposes
window.supabase = supabase;

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="flex">
          <div className="flex-1">
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route
                path="/trends"
                element={
                  <ProtectedRoute>
                    <Trends />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/discover"
                element={
                  <ProtectedRoute>
                    <Discover />
                  </ProtectedRoute>
                }
              />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route path="/password-reset" element={<PasswordReset />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
