import React from 'react';
import { HomeIcon, ArrowTrendingUpIcon, ArrowRightIcon } from '@heroicons/react/24/outline'; // Example imports, add more as needed
import { Link, useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar() {
  const location = useLocation();
  const navigation = [
    { name: 'Database', href: '/trends', icon: ArrowTrendingUpIcon },
    { name: 'Discovery', href: '/discover', icon: ArrowTrendingUpIcon },
  ];

  return (
    <div className="fixed flex flex-col h-screen gap-y-3 overflow-y-auto border-r border-gray-200 bg-white px-6">
      <div className="flex h-16 shrink-0 items-center">
        <img
          alt="Your Company"
          src="/logo.png" // Use the same logo as in the header
          className="h-4 w-auto"
        />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-3">
          {navigation.map((item) => {
            const isActive = location.pathname === item.href;
            return (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={classNames(
                    isActive
                      ? 'bg-blue-50 text-blue-500'
                      : 'text-gray-900 hover:bg-gray-100 hover:text-blue-500',
                    'group flex gap-x-3 rounded-md p-1.5 text-sm font-medium leading-6 ',
                  )}
                >
                  <item.icon
                    aria-hidden="true"
                    className={classNames(
                      isActive ? 'text-blue-500' : 'text-gray-400 group-hover:text-blue-500',
                      'h-6 w-6 shrink-0 transition-colors duration-200',
                    )}
                  />
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="mt-auto mb-4">
        <Link to="/profile" className="text-sm font-semibold leading-6 text-gray-900 flex items-center">
          Profile
          <ArrowRightIcon className="h-4 w-4 ml-1" aria-hidden="true" />
        </Link>
      </div>
    </div>
  );
}
