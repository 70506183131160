import React from 'react';

const stats = [
  { name: 'Ashwagandha', stat: '3x', previousStat: 'since reported' },
  { name: 'Korean Skincare', stat: '2.5x', previousStat: 'since reported' },
  { name: 'Mushroom Coffee', stat: '5x', previousStat: 'since reported' },
  { name: 'Permanent Jewelry', stat: '2.3x', previousStat: 'since reported' },
  { name: 'Barefoot Shoes', stat: '3x', previousStat: 'since reported', hideOnMobile: true },
];

export default function Example() {
  return (
    <div className="max-w-5xl mx-auto py-16 px-6 sm:px-10 lg:px-10">
      {/* Title Section */}
      <section id="trends-title" className="bg-gray-50">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-1">
            <h2 className="text-3xl sm:text-4xl font-bold text-black">
              We have a history of <span className="bg-clip-text text-transparent bg-gradient-to-r from-sky-400 to-blue-700">predicting the future</span>
            </h2>
            <p className="text-gray-700 text-md sm:text-lg py-4">Here are a few products that we caught early</p>

          </div>
        </div>
      </section>

      <dl className="mt-2 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-5">
        {stats.map((item) => (
          <div
            key={item.name}
            className={`px-2 py-3 sm:p-4 bg-white rounded-lg shadow-md ${item.hideOnMobile ? 'hidden sm:block' : ''}`}
          >
            <dt className="text-sm font-medium text-gray-900 truncate">{item.name}</dt>
            <dd className="mt-1">
              <div className="flex items-baseline">
                <span className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-sky-500 to-blue-700">
                  {item.stat}
                </span>
                <span className="ml-1 text-xs font-medium text-gray-500">{item.previousStat}</span>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
