import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Ensure this path is correct for your project
import Notification from './SignInNotification'; // Import the Notification component

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState(''); // New state for password reset email
  const [showResetForm, setShowResetForm] = useState(false); // New state to manage visibility of reset form
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [showNotification, setShowNotification] = useState(false); // State for showing notification
  const [notificationMessage, setNotificationMessage] = useState(''); // State for notification message
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) {
        if (error.message === 'Invalid login credentials') {
          setErrorMessage('The email or password you entered is incorrect. Please try again.');
          setNotificationMessage("If you were a user before the Trending Data 2.0 update, you'll need to reset your password"); // Custom message
        } else {
          setErrorMessage(error.message);
          setNotificationMessage(error.message);
        }
        throw error;
      }
  
      // Fetch JWT token from your server
      const response = await fetch('https://api.trendingdata.ai/generate-jwt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const { token } = await response.json();
  
      // Store the token in localStorage
      localStorage.setItem('jwtToken', token);
  
      navigate('/trends'); // Navigate to the /trends route upon successful sign-in
    } catch (error) {
      setShowNotification(true); // Show notification
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(resetEmail, {
        redirectTo: 'https://www.trendingdata.ai/password-reset'
      });
      if (error) throw error;
      setErrorMessage('Password reset email sent!');
      setNotificationMessage('Password reset email sent!');
      setShowNotification(true); // Show notification
    } catch (error) {
      setErrorMessage(error.message); // Set error message
      setNotificationMessage(error.message); // Set notification message
      setShowNotification(true); // Show notification
    }
  };
  

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Notification show={showNotification} setShow={setShowNotification} message={notificationMessage} type="error" />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-5 w-auto"
          src="/logo.png"
          alt="Company Logo" // Added alt attribute
        />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {errorMessage && (
            <div className="mb-4 text-red-500 text-sm">
              {errorMessage}
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSignIn}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-6">
                <button
                  type="button"
                  className="font-semibold text-blue-500 hover:text-blue-400"
                  onClick={() => setShowResetForm(true)}
                >
                  Forgot password?
                </button>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Sign in
              </button>
            </div>
          </form>

          {showResetForm && (
            <form className="mt-6 space-y-6" onSubmit={handlePasswordReset}>
              <div>
                <label htmlFor="reset-email" className="block text-sm font-medium leading-6 text-gray-900">
                  Enter your email to reset password
                </label>
                <div className="mt-2">
                  <input
                    id="reset-email"
                    name="reset-email"
                    type="email"
                    autoComplete="email"
                    required
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Reset Password
                </button>
              </div>
            </form>
          )}
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{' '}
          <a href="/signup" className="font-semibold leading-6 text-blue-500 hover:text-blue-400">
            Sign up now
          </a>
        </p>
      </div>
    </div>
  );
}
