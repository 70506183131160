import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { getCommonChartOptions } from './utils/chartConfig';
import CatButton from './utils/CatButton';
import FilterButton from './utils/FilterButton';
import DateRangeButton from './utils/DateRangeButton';
import { sortByMostGrowth, sortByLeastGrowth, sortByOriginalOrder, sortByMostVolume, sortByLeastVolume, filterByDateRange } from './utils/Sorting';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import Sidebar from './utils/Sidebar';

const fetchDataFromServer = async (navigate) => { // Accept navigate as a parameter
  const token = localStorage.getItem('jwtToken'); // Retrieve the JWT token from localStorage

  if (!token) {
    console.error('No JWT token found. User is not authenticated.');
    navigate('/signin'); // Redirect to /signin if no token is found
    return [];
  }

  try {
    const response = await fetch('https://api.trendingdata.ai/sectrends', {
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 401) {
      navigate('/signin'); // Redirect to /signin if the response status is 401
      return [];
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num;
};

const isAllZeros = (array) => {
  return array.every(item => Number(item[1]) === 0);
};

function Trends() {
  const [chartDataSets, setChartDataSets] = useState([]);
  const [filteredDataSets, setFilteredDataSets] = useState([]);
  const [showDropdown, setShowDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedFilter, setSelectedFilter] = useState('Original Order');
  const [selectedDateRange, setSelectedDateRange] = useState('5 Years');
  const [originalDataSets, setOriginalDataSets] = useState([]);
  const itemsPerPage = 51;
  const dropdownRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const getChartData = async () => {
      const fetchedData = await fetchDataFromServer(navigate); // Pass navigate to fetchDataFromServer
      setChartDataSets(fetchedData);
      setFilteredDataSets(fetchedData);
      setOriginalDataSets(fetchedData);
    };
    getChartData();
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const search = (platform, chartName) => {
    let url;
    switch (platform) {
      case 'Google':
        url = `https://www.google.com/search?q=${encodeURIComponent(chartName)}`;
        break;
      case 'TikTok':
        url = `https://www.tiktok.com/search?q=${encodeURIComponent(chartName)}`;
        break;
      case 'Amazon':
        url = `https://www.amazon.com/s?k=${encodeURIComponent(chartName)}`;
        break;
      case 'Google Trends':
        url = `https://trends.google.com/trends/explore?date=today%205-y&geo=US&q=${encodeURIComponent(chartName)}&hl=en`;
        break;
      default:
        url = '';
    }
    if (url) window.open(url, '_blank');
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDataSets = filteredDataSets.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredDataSets.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    console.log('Category selected:', category);
    applyFilters(category, selectedFilter, selectedDateRange);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    console.log('Filter selected:', filter);
    applyFilters(selectedCategory, filter, selectedDateRange);
  };

  const handleDateRangeSelect = (range) => {
    setSelectedDateRange(range);
    console.log('Date range selected:', range);
    applyFilters(selectedCategory, selectedFilter, range);
  };

  const applyFilters = (category, filter, range) => {
    console.log('Applying filters with:', { category, filter, range });
    let filteredData = [...chartDataSets];

    // Apply category filter
    if (category !== 'All') {
      filteredData = filteredData.filter((dataset) => dataset.category === category);
    }
    console.log('Data after category filter:', filteredData);

    // Apply date range filter
    filteredData = filterByDateRange(filteredData, range);
    console.log('Data after date range filter:', filteredData);

    // Apply sorting filter
    switch (filter) {
      case 'Most Growth':
        filteredData = sortByMostGrowth(filteredData);
        break;
      case 'Least Growth':
        filteredData = sortByLeastGrowth(filteredData);
        break;
      case 'Original Order':
        // Ensure the original order is applied to the filtered data
        filteredData = filteredData.map(dataset => {
          return originalDataSets.find(original => original.name === dataset.name) || dataset;
        });
        break;
      case 'Most Volume':
        filteredData = sortByMostVolume(filteredData);
        break;
      case 'Least Volume':
        filteredData = sortByLeastVolume(filteredData);
        break;
      default:
        break;
    }
    console.log('Data after sorting filter:', filteredData);

    setFilteredDataSets(filteredData);
    setCurrentPage(1); // Reset to first page when filters change
  };

  const getTimeUnit = (range) => {
    return range === '5 Years' ? 'year' : 'month';
  };

  const getMaxTicksLimit = (range) => {
    return range === '5 Years' ? 12 : 4; // Adjust this value based on your needs
  };

  return (
    <div className="flex">
      <div className="hidden md:block">
        <Sidebar /> {/* Include the Sidebar component */}
      </div>
      <div className="flex-grow md:ml-52"> {/* Add left margin to main content on desktop */}
        <div className="flex justify-center space-x-4 mt-8">
          <CatButton selected={selectedCategory} onSelectCategory={handleCategorySelect} />
          <FilterButton selected={selectedFilter} onSelectFilter={handleFilterSelect} />
          <DateRangeButton selected={selectedDateRange} onSelectDateRange={handleDateRangeSelect} />
        </div>
        <div className="p-2 md:p-4 lg:p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 md:gap-2 lg:gap-4 p-2 md:p-4 lg:p-6 mx-auto max-w-[1200px]">
            {currentDataSets.map((dataset, index) => {
              const useDataArray = isAllZeros(dataset.searchVol);
              const dataToUse = useDataArray ? dataset.data : dataset.searchVol;
              const maxYValue = Math.max(...dataToUse.map(item => Number(item[1])));
              const lastValue = dataToUse[dataToUse.length - 1][1];
              const formattedLastValue = useDataArray ? 'N/A' : formatNumber(lastValue);
              const chartOptions = getCommonChartOptions(getTimeUnit(selectedDateRange), getMaxTicksLimit(selectedDateRange), maxYValue);

              return (
                <div key={index} className="bg-white rounded-lg shadow p-2 md:p-4 lg:p-5 flex flex-col border-2 border-blue-100 relative">
                  <div className="flex justify-between items-center mb-1 md:mb-2 lg:mb-3 ">
                    <h2
                      className="text-lg md:text-base lg:text-lg font-semibold cursor-pointer"
                      onClick={() => search('Google', dataset.name)}
                    >
                      {dataset.name}
                    </h2>
                    <div className="flex items-center">
                      <div className="text-center mr-1 md:mr-2 lg:mr-3">
                        <span className="text-sm md:text-sm lg:text-lg text-blue-500 font-semibold">{formattedLastValue}</span>
                        <div className="text-xs text-gray-400">Volume</div>
                      </div>
                      <div className="text-center">
                        <span className="text-sm md:text-sm lg:text-lg text-green-500 font-semibold">{dataset.growth}</span>
                        <div className="text-xs text-gray-400">Growth</div>
                      </div>
                      <button
                        className="bg-blue-500 text-white px-1 md:px-1 lg:px-1 py-1 rounded-md shadow hover:bg-blue-600 transition duration-300 text-xs md:text-xs lg:text-xs relative ml-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDropdown(showDropdown === index ? null : index);
                        }}
                      >
                        ▼
                      </button>
                    </div>
                  </div>
                  <div className="flex-grow" style={{ height: '200px' }}>
                    <Line 
                      data={{ 
                        labels: dataToUse.map(item => item[0]), // Dates for x-axis
                        datasets: [{
                          label: dataset.name,
                          data: dataToUse.map(item => item[1]), // Values for y-axis
                          borderColor: '#007BFF', // Explicitly set the line color
                          backgroundColor: 'rgba(75,192,192,0.2)',
                        }]
                      }} 
                      options={chartOptions} 
                    />
                  </div>
                  {/* Display the description if it exists */}
                  {dataset.description && (
                    <p className="mt-1 md:mt-2 lg:mt-3 text-gray-700 text-xs md:text-xs lg:text-sm">{dataset.description}</p>
                  )}
                  {/* Dropdown content */}
                  {showDropdown === index && (
                    <div ref={dropdownRef} className="absolute right-0 mt-2 w-36 md:w-48 bg-white border border-gray-200 rounded shadow-lg z-10 text-xs">
                      <ul>
                        <li className="px-2 md:px-4 py-1 md:py-2 hover:bg-gray-100 cursor-pointer" onClick={() => search('Google', dataset.name)}>Google</li>
                        <li className="px-2 md:px-4 py-1 md:py-2 hover:bg-gray-100 cursor-pointer" onClick={() => search('TikTok', dataset.name)}>TikTok</li>
                        <li className="px-2 md:px-4 py-1 md:py-2 hover:bg-gray-100 cursor-pointer" onClick={() => search('Amazon', dataset.name)}>Amazon</li>
                        <li className="px-2 md:px-4 py-1 md:py-2 hover:bg-gray-100 cursor-pointer" onClick={() => search('Google Trends', dataset.name)}>Google Trends</li>
                      </ul>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex justify-center items-center mb-10 md:mb-20 mt-2 md:mt-4">
            <div className="join">
              {currentPage > 1 && (
                <button
                  className="join-item btn btn-sm"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  «
                </button>
              )}
              <span className="join-item btn btn-sm">
                Page {currentPage}
              </span>
              {currentPage < pageNumbers.length && (
                <button
                  className="join-item btn btn-sm"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  »
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trends;
