import React, { useState } from 'react';
import NewsletterHero from './imgs/newsletterhero.png'; // Import the image
import Typewriter from './utils/Typewriter'; // Import the Typewriter component
import fulldbssImage from './imgs/fulldbss.png'; // Import the DBSS image

import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await emailSubscriber(email);
      setIsOpen(true); // Show the popup on successful subscription
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const emailSubscriber = async (email) => {
    const response = await fetch('https://api.trendingdata.ai/create-email-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        send_welcome_email: true // Ensure the welcome email is sent
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#F5FCFF] to-[#79DAF3]">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-12 flex flex-col lg:flex-row">
        {/* Left section for text and input */}
        <div className="lg:w-1/2 flex flex-col justify-center lg:pr-8">
          <div className="text-left lg:text-left">
            <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-7xl">
              Where <br />
              <Typewriter />
              <br />
              find the next big thing
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-800">
              The fastest growing products and businesses sent straight to your inbox, for free.
            </p>
            <div className="mt-5 flex flex-col sm:flex-row justify-start w-full max-w-md">
              <form className="flex flex-col sm:flex-row w-full" onSubmit={handleSubmit}>
                <div className="flex-grow">
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                    placeholder="email@example.com"
                    value={email}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="mt-2 sm:mt-0 sm:ml-2 w-full sm:w-auto rounded-md bg-black px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"                >
                  Subscribe
                </button>
              </form>
            </div>
            <div className="mt-6 text-sm text-gray-800">
              <p>
                Check out our database of trends{' '}
                <a href="/" className="text-blue-500 underline">
                  here
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        {/* Right section for image */}
        <div className="lg:w-1/2 flex items-center justify-center lg:pl-8 mt-8 lg:mt-0">
          <img
            src={NewsletterHero}
            alt="Newsletter"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>

      {/* Popup Dialog */}
      <Transition show={isOpen}>
        <Dialog className="relative z-10" onClose={() => setIsOpen(false)}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                  <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 p-6 flex flex-col justify-center">
                      <DialogTitle as="h3" className="text-4xl font-semibold leading-8 text-gray-900 mt-3">
                        Congrats! You're in! 🎉
                      </DialogTitle>
                      <div className="py-5 text-left">
                        Join <span className="text-blue-500"> Trends Unlimited</span> for access to our growing database.
                        <ul className="mt-4 text-black">
                          <li className="mt-2 flex items-start">
                            <span className="mr-2">+</span>
                            <span><strong>Data Driven.</strong> We analyze millions of data points to find emerging trends first.</span>
                          </li>
                          <li className="mt-2 flex items-start">
                            <span className="mr-2">+</span>
                            <span><strong>Save Time & Money.</strong> Stop wasting time searching for the next big thing.</span>
                          </li>
                          <li className="mt-2 flex items-start">
                            <span className="mr-2">+</span>
                            <span><strong>Trend Filters.</strong> Powerful filtering for efficient trend discovery.</span>
                          </li>
                          <li className="mt-2 flex items-start">
                            <span className="mr-2">+</span>
                            <span><strong>No Subscription.</strong> Pay once and you're subscribed for life.</span>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-3 py-2 text-sm text-white shadow-sm hover:bg-gradient-to-r hover:from-blue-600 hover:to-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          onClick={() => setIsOpen(false)}
                        >
                          Get Lifetime Access
                        </button>
                      </div>
                      <div className="mt-4 flex justify-center">
                        <button
                          type="button"
                          className="text-gray-500 text-sm underline underline-offset-2"
                          onClick={() => setIsOpen(false)}
                        >
                          No thanks, I only want a few trends a month
                        </button>
                      </div>
                    </div>
                    <div className="bg-gradient-to-r from-cyan-100 via-blue-300 to-blue-400 md:w-1/2 flex items-center justify-center">
                      <img
                        src={fulldbssImage}
                        alt="Newsletter"
                        className="w-full sm:w-5/6 h-auto object-contain rounded-md"
                      />
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

    </div>
  );
};

export default Newsletter;
